import React from 'react';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ReactPlayer from 'react-player';
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';

import Layout from '../components/Layout';
import IconFB from '../components/Icons/facebook';
import IconTwitter from '../components/Icons/twitter';
import IconMailSm from '../components/Icons/mail-sm';
import {
  IconVideo,
  IconText,
  IconAudio,
  IconVeranstaltung,
  IconSchulung
} from '../components/Icons';

import { BVOG_URL, OFAKT_URL } from '../constants';
import { concateneteHashtags } from '../utils/common';

const renderContent = content => {
  switch (content.__typename) {
    case 'DatoCmsBvogModText':
      return <div dangerouslySetInnerHTML={{ __html: content.bvogModTextText }} />;

    case 'DatoCmsBvogModPicture':
      return content.bvogModPictureImage ? (
        <img
          src={content.bvogModPictureImage.url}
          alt={content.bvogModPictureImage.title || content.bvogModPictureImage.alt || ''}
        />
      ) : null;

    case 'DatoCmsBvogModInfo':
      return (
        (content.bvogModInfoAuthor || content.bvogModInfoDate) && (
          <div className="d-flex justify-content-between">
            {content.bvogModInfoDate && <span>{content.bvogModInfoDate}</span>}
            {content.bvogModInfoAuthor && <span>{content.bvogModInfoAuthor}</span>}
          </div>
        )
      );

    case 'DatoCmsBvogModVideo':
      return content.bvogModVideoVideo ? (
        <ReactPlayer
          url={content.bvogModVideoVideo.url}
          controls
          style={{ marginBottom: '16px' }}
        />
      ) : null;

    case 'DatoCmsBvogModPodcast':
      return content.bvogModPodcastPodcast ? (
        <ReactPlayer url={content.bvogModPodcastPodcast} style={{ marginBottom: '16px' }} />
      ) : null;

    case 'DatoCmsBvogSocialPost':
      return (
        <div
          className="d-flex justify-content-center"
          dangerouslySetInnerHTML={{ __html: content.embedCode }}
        />
      );

    case 'DatoCmsBvogDocument':
      return content.document.map((item, index) => (
        <a key={index} href={item.url} download>
          <button>{item.title || item.alt || 'Download File'}</button>
        </a>
      ));

    case 'DatoCmsBvogModExternalLink':
      return null;

    default:
      console.log('Error in renderContent');
  }
};

const Page = ({ data, pageContext }) => {
  const post = data.allDatoCmsBeitragBvogOfakt.edges[0].node;

  const isPostHaveSocialContent = post.bvogContentModules.some(
    content => content.__typename === 'DatoCmsBvogSocialPost'
  );

  /* SEO */
  const seoTitle = (post.seo && post.seo.title) || post.bvogTitel;
  const seoDescription =
    (post.seo && post.seo.description) ||
    concateneteHashtags(
      post.hashtag,
      post.themenschwerpunkt,
      post.oesterreichbilanz,
      post.arbeitsweise
    );

  return (
    <Layout
      title={seoTitle}
      description={seoDescription}
      twitterCard={post.seo && post.seo.twitterCard}
      imageURL={post.seo && post.seo.image && post.seo.image.path}
    >
      {isPostHaveSocialContent && (
        <Helmet>
          <script async src="https://platform.twitter.com/widgets.js" />
          <script async src="//www.instagram.com/embed.js" />
        </Helmet>
      )}

      <div className="common-page article-page">
        <div className="subheader">
          <h3>{`${post.bvogFrontTitel || post.bvogTitel || post.beitragsname}`}</h3>
        </div>

        <div className="container">
          <div className="wrapper__post-card">
            <div className="post-card">
              <div className="post-card__image">
                <Img
                  fluid={post.bvogMainPicture.fluid}
                  alt={post.bvogTitel || post.beitragsname}
                  style={{ height: '100%' }}
                />
                <div className="post-card__image__top">
                  <div className="content-hashtags">
                    {post.themenschwerpunkt.map((tag, index) => (
                      <span key={index}>#{tag.link}</span>
                    ))}
                  </div>

                  <div className="content-icons">
                    {post.bvogContentModules.some(
                      content => content.__typename === 'DatoCmsBvogModText'
                    ) && <IconText />}
                    {post.bvogVeranstaltungen.length > 0 && <IconVeranstaltung />}
                    {post.bvogContentModules.some(
                      content => content.__typename === 'DatoCmsBvogModVideo'
                    ) && <IconVideo />}
                    {post.bvogContentModules.some(
                      content => content.__typename === 'DatoCmsBvogModPodcast'
                    ) && <IconAudio />}
                  </div>
                </div>
              </div>

              <div className="post-card__label">
                <div className="post-card__label__wrapper">
                  <h3>{pageContext.page}</h3>
                  <h2 className="title title--white">{post.bvogTitel || post.beitragsname}</h2>
                </div>
              </div>
            </div>
            <span>{post.bvogMainPicture && post.bvogMainPicture.title}</span>
            {post.bvogFrontText && (
              <div className="post-page--bvog-front-text">{post.bvogFrontText}</div>
            )}
          </div>

          <div className="post-page__wrapper">
            <div className="post-page__social">
              <div>
                <FacebookShareButton url={`${BVOG_URL}/beitraege/${post.link}`}>
                  <IconFB />
                </FacebookShareButton>
              </div>
              <div>
                <TwitterShareButton url={`${BVOG_URL}/beitraege/${post.link}`}>
                  <IconTwitter />
                </TwitterShareButton>
              </div>
              <div>
                <EmailShareButton url={`${BVOG_URL}/beitraege/${post.link}`}>
                  <IconMailSm />
                </EmailShareButton>
              </div>
            </div>
            <div className="post-page__content">
              {post.bvogContentModules.map((content, index) => (
                <div className="post-page__content__module" key={index}>
                  {renderContent(content)}
                </div>
              ))}

              <div className="links">
                {post.bvogBvogBeitraege.length > 0 && <h4>EMPFOHLENE BEITRÄGE AUF BVOG.AT:</h4>}
                {post.bvogBvogBeitraege.map(({ link, beitragsname }, index) => (
                  <div key={index}>
                    <Link to={`/beitraege/${link}`}>{beitragsname}</Link>
                  </div>
                ))}
              </div>

              <div className="links">
                {post.bvogVeranstaltungen.length > 0 && <h4>Veranstaltungen:</h4>}
                {post.bvogVeranstaltungen.map(({ link, beitragsname }, index) => (
                  <div key={index}>
                    <Link to={`/veranstaltungen/${link}`}>{beitragsname}</Link>
                  </div>
                ))}
              </div>

              <div className="links">
                {post.bvogOfaktBeitraege.length > 0 && <h4>OFAKT:</h4>}
                {post.bvogOfaktBeitraege.map(({ link, beitragsname, hashtag }, index) => (
                  <div key={index}>
                    <a
                      href={`${OFAKT_URL}/tag/${hashtag.split(', ')[0]}/${link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {beitragsname}
                    </a>
                  </div>
                ))}
              </div>

              {post.bvogContentModules.some(
                content => content.__typename === 'DatoCmsBvogModExternalLink'
              ) && (
                <div>
                  <h4 style={{ marginBottom: '1rem' }}>Externe empfohlene Beiträge:</h4>
                  {post.bvogContentModules
                    .filter(content => content.__typename === 'DatoCmsBvogModExternalLink')
                    .map((content, index) => (
                      <div
                        className="post-page__content__module post-page__content__module--link"
                        key={index}
                      >
                        <a href={content.bvogModExternalLinkUrl}>
                          {content.bvogModExternalLinkImage && (
                            <Img
                              fluid={content.bvogModExternalLinkImage.fluid}
                              alt={content.bvogModExternalLinkTitel || ''}
                              style={{ width: 75, height: 75 }}
                            />
                          )}
                          <div className="link-data">
                            {content.bvogModExternalLinkTitel && (
                              <span>{content.bvogModExternalLinkTitel}</span>
                            )}
                            <i>{content.bvogModExternalLinkUrl}</i>
                          </div>
                        </a>
                      </div>
                    ))}
                </div>
              )}

              {post.hashtag && (
                <div className="tags">
                  {post.hashtag.split(',').map((tag, index) => (
                    <Link
                      key={index}
                      to={`/search?query=${tag.trim()}`}
                      state={{ searchQuery: tag.trim() }}
                    >
                      <div>#{tag.trim()}</div>
                    </Link>
                  ))}
                  {post.themenschwerpunkt.map((tag, index) => (
                    <Link
                      key={index}
                      to={`/search?query=${tag.link.trim()}`}
                      state={{ searchQuery: tag.link.trim() }}
                    >
                      <div>#{tag.link}</div>
                    </Link>
                  ))}
                  {post.oesterreichbilanz.map((tag, index) => (
                    <Link
                      key={index}
                      to={`/search?query=${tag.link.trim()}`}
                      state={{ searchQuery: tag.link.trim() }}
                    >
                      <div>#{tag.link}</div>
                    </Link>
                  ))}
                  {post.arbeitsweise.map((tag, index) => (
                    <Link
                      key={index}
                      to={`/search?query=${tag.link.trim()}`}
                      state={{ searchQuery: tag.link.trim() }}
                    >
                      <div>#{tag.link}</div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query($slug: String!) {
    allDatoCmsBeitragBvogOfakt(filter: { bvog: { eq: true }, link: { eq: $slug } }) {
      edges {
        node {
          ...DatoBeitragFields
        }
      }
    }
  }
`;
